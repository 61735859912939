import { configuration } from "../helpers/config";
import React, { useEffect, useState } from "react";
import axios from "axios";
import LobPage from "../components/LOBPage";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import MdwHomepage from "./mdw-homepage";
import { homePageFetcher } from "../utils/GenUtils";
import Homepage from "../components/Homepage";
import { MenuFunction,FooterFunction } from "../utils/menu";
import {
  IS_DEALER_WEBSITE,
  TOGGLE_IS_LOB_PAGE,
  UPDATE_DEALER_CODE,
  UPDATE_LOB_VALUE,
} from "../redux/ActionTypes";
import { FetchModel } from "../utils/apiCall";


export async function getServerSideProps(context) {
  const { req } = context;
  const currentUrl = `${req.headers.host}`;
  const apiData = await axios.get(
    // `${configuration.APP_URL_CONTENTS}/dealer-code?url=website-d1.suzukibusinesscloud-dev.com`,
    `${configuration.APP_URL_CONTENTS}/dealer-code?url=${currentUrl}`,
  );
  const lobData = apiData?.data?.result?.[0] || "" ;
  const Menudata = await MenuFunction(lobData?.dealerCode);
  const Footerdata = await FooterFunction(lobData?.dealerCode);
  const modeldata = await FetchModel();
  if(configuration.SHOW_LOB_PAGE==="true"){
    let props = { 
    lobData: {},   
    Menudata:Menudata,
    Footerdata:Footerdata,
    modeldata:modeldata,
   };
  if (configuration.WEBSITE_TYPE == "Distributor") {
   
    props.defaultPage = "landing-page";
    // load landing-page API;
    try {
      const res = await axios.get(
        `${configuration.APP_URL_CONTENTS}/landing-page`,
      );
      props.lobData.dealerCode = "Distributor";
      if (res.data[0].default_page !== "landing-page") {
        switch (res.data[0].default_page) {
          case "4w":
            props.lobData.lob = 1;
            return {
              redirect: {
                destination: "/cars",
                permanent: false,
              },
            };
          case "2w":
            props.lobData.lob = 2;
            return {
              redirect: {
                destination: "/bike",
                permanent: false,
              },
            };
          case "marine":
            props.lobData.lob = 3;
            return {
              redirect: {
                destination: "/marine",
                permanent: false,
              },
            };
          default:
            return {
              redirect: {
                destination: "/404",
                permanent: false,
              },
            };
        }
      } else {
        props.data = res.data[0];
      }
    } catch (e) {
      console.log(e, "Error with landing page API");
    }
  } else {
    try {
      const { req } = context;
      const currentUrl = `${req.headers.host}`;
      const apiData = await axios.get(
        // `${configuration.APP_URL_CONTENTS}/dealer-code?url=website-d1.suzukibusinesscloud-dev.com`,
        `${configuration.APP_URL_CONTENTS}/dealer-code?url=${currentUrl}`,
      );
      const lobData = apiData?.data?.result?.[0];
      props.lobData = lobData;

      await homePageFetcher(props, lobData.lob, lobData.dealerCode);
          let modelArr = [];
      const getModelData = async () => {
        try {
          const response = await fetch(
            `${configuration.APP_URL_CONTENTS}/home-page?lob=${lobData.lob}&dealer_code=null`,
            configuration.HEADER_DEALER_LOCATOR_VAPT,
          );
          const data = await response.json();
         // conslole.log("homoe 1", data);
          modelArr = await Promise.all(
            data[0]?.cars?.map(async (x) => {
              let obj = {};
              obj.tab = x.title;
      
              // Fetch model data and filter out undefined values
              const modelData = await Promise.all(
                x?.cars.map(async (item) => {
                  try {
                    const res = await axios.get(
                      `${configuration.APP_URL_PRODUCTS}model/front/${item?._id}`
                    );
                    return res?.data?.data || null; // Ensure `null` is returned instead of `undefined`
                  } catch (error) {
                    console.error(`Error fetching data for item ${item?._id}:`, error);
                    return null; // Return `null` on error to avoid breaking the array
                  }
                })
              );
      
              // Filter out any `null` values
              obj.modelData = modelData.filter((data) => data !== null);
      
              return obj;
            })
          );
      
          props.models = modelArr; // Ensure props is updated after all data is fetched
        } catch (error) {
        }
      };
      
      await getModelData();
    } catch (e) {
    }
  }
  return { props };
}else{
  const { req } = context;
  const currentUrl = `${req.headers.host}`;
  const apiData = await axios.get(
    // `${configuration.APP_URL_CONTENTS}/dealer-code?url=website-d1.suzukibusinesscloud-dev.com`,
    `${configuration.APP_URL_CONTENTS}/dealer-code?url=${currentUrl}`,
  );

  const lobData = apiData?.data?.result?.[0] || "" ;
 
  // fetching homepage and cms-page data and pairing the data id respective ids.
  let props = {
    isLobDefault: false,
    data: {},
    error: false,
    lobData: lobData,
    Menudata:Menudata,
    Footerdata:Footerdata,
    modeldata:modeldata,
  };

  await homePageFetcher(
    props,
    lobData?.isDealerWebsite
      ? apiData?.data?.result?.[0]?.lob
      : configuration.COMMON_MASTER_LOB,
    apiData?.data?.result?.[0]?.dealerCode,
  );

  const res = await axios.get(
    `${configuration.APP_URL_PRODUCTS}model?lob=${configuration.COMMON_MASTER_LOB}`,
    configuration.HEADER_DEALER_LOCATOR_VAPT,
  );
  function transformModelData(data) {
    const groupedData = {};

    data?.forEach((item) => {
      if (!groupedData[item.vehicle_type]) {
        groupedData[item.vehicle_type] = [];
      }
      groupedData[item.vehicle_type].push(item);
    });

    return Object.keys(groupedData).map((key) => ({
      tab: key,
      modelData: groupedData[key],
    }));
  }

  // Example usage:
  const transformedData = transformModelData(res?.data?.data);
  props.models = transformedData;
  if (props.error) {
    return {
      redirect: {
        destination: "/404",
        permanent: false,
      },
    };
  } else {
    return { props };
  }
}
}

export default function Main(props) {
  const dispatch = useDispatch();
  const { asPath } = useRouter();

  useEffect(() => {
    // props.default_page === "landing-page" && asPath === "/"
    //   ? dispatch({ type: TOGGLE_IS_LOB_PAGE, payload: true })
    //   : dispatch({
    //       type: TOGGLE_IS_LOB_PAGE,
    //       payload: false,
    //     });
    dispatch({ type: UPDATE_LOB_VALUE, payload: null });
    return () => {
      dispatch({ type: TOGGLE_IS_LOB_PAGE, payload: false });
    };
  }, []);

  useEffect(() => {
    dispatch({
      type: UPDATE_LOB_VALUE,
      payload: null,
    });
    dispatch({
      type: IS_DEALER_WEBSITE,
      payload: props.lobData.isDealerWebsite,
    });
    dispatch({
      type: UPDATE_DEALER_CODE,
      payload: props.lobData.dealerCode,
    });
  }, []);

  const [testimonials, setTestimonials] = useState([]);
  const reduxData = useSelector(({ app }) => app);
  const initialState = {
    titleColor: "",
    titleFontSize: "",
    descColor: "",
    descFontSize: "",
    ctaColor: "",
    ctaFontSize: "",
  };
  const [bannerThemeData, setBannerThemeData] = useState(initialState);

  useEffect(() => {
    if (reduxData?.dealerCode != "") {
      (async () => {
        try {
          if(LOB_VALUE!==null){
          const res = await axios.get(
            `${configuration.APP_URL_CONTENTS}/settings?lob=${configuration.API_LOB}&dealer_code=${reduxData.dealerCode}`,
          );
          const themeData = res.data[0].theme_settings?.find(
            (x) => x?.applied === true,
          );
          setBannerThemeData({
            ...bannerThemeData,
            titleColor: themeData?.homepage_banner1_title_color,
            titleFontSize: themeData?.homepage_banner1_title_font_size,
            descColor: themeData?.homepage_banner1_desc_color,
            descFontSize: themeData?.homepage_banner1_desc_font_size,
            ctaColor: themeData?.homepage_banner1_cta_color,
            ctaFontSize: themeData?.homepage_banner1_cta_font_size,
          });
        }
        } catch (err) {
          //console.log(err.message);
        }
      })();
    }
  }, [reduxData.dealerCode]);

  const LOB_VALUE=useSelector(state=>state?.app?.LobValue);
  
  useEffect(() => {
    if (!LOB_VALUE) return;
    if (reduxData.dealerCode != "") {
      (async () => {
        try {
          if(LOB_VALUE!==null){
          const res = await axios.get(
            `${configuration.APP_URL_CONTENTS}/testimonial?lob=${LOB_VALUE}&dealer_code=${reduxData.dealerCode}`,
          );
          setTestimonials(res?.data);
        }
        } catch (e) {
          //console.log(e);
        }
      })();
    }
  }, [reduxData.dealerCode,LOB_VALUE]);





  useEffect(() => {
    if (!LOB_VALUE) return;
    if (reduxData.dealerCode != "") {
      (async () => {
        try {
          if(LOB_VALUE!==null){
          const res = await axios.get(
            `${configuration.APP_URL_CONTENTS}/testimonial?lob=${LOB_VALUE}&dealer_code=${reduxData.dealerCode}`,
          );
          setTestimonials(res?.data);
        }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [reduxData?.dealerCode, LOB_VALUE]);

  useEffect(() => {
    if (reduxData.dealerCode != "") {
      (async () => {
        try {
          if(LOB_VALUE!==null){
          const res = await axios.get(
            `${configuration.APP_URL_CONTENTS}/settings?lob=${configuration.API_LOB}&dealer_code=${reduxData.dealerCode}`,
          );
          const themeData = res.data[0].theme_settings?.find(
            (x) => x?.applied === true,
          );

          setBannerThemeData({
            ...bannerThemeData,
            titleColor: themeData?.homepage_banner1_title_color,
            titleFontSize: themeData?.homepage_banner1_title_font_size,
            descColor: themeData?.homepage_banner1_desc_color,
            descFontSize: themeData?.homepage_banner1_desc_font_size,
            ctaColor: themeData?.homepage_banner1_cta_color,
            ctaFontSize: themeData?.homepage_banner1_cta_font_size,
          });
        }
        } catch (err) {
          console.error(err.message);
        }
      })();
    }
  }, [reduxData.dealerCode]);

  useEffect(() => {
    dispatch({
      type: IS_DEALER_WEBSITE,
      payload: props?.lobData?.isDealerWebsite,
    });
    dispatch({
      type: UPDATE_DEALER_CODE,
      payload: props?.lobData?.dealerCode,
    });
  }, [props?.lobData]);

  useEffect(() => {
    dispatch({
      type: UPDATE_LOB_VALUE,
      payload: configuration.COMMON_MASTER_LOB,
    });
    if (configuration.WEBSITE_TYPE == "Distributor") {
      dispatch({
        type: UPDATE_DEALER_CODE,
        payload: null,
      });
    }
  }, []);


  return (
    <>
    {props.defaultPage === "landing-page" ? (
      <LobPage banners={props?.data?.banners} />
    ) :(!props?.lobData?.isDealerWebsite && configuration.SHOW_DEALER_WEBSITE!=="true") ? (
      <Homepage {...props} />
    ) : (
      <MdwHomepage
        data={props?.data?.[0]}
        modelData={props.models}
        testimonials={testimonials}
        bannerThemeData={bannerThemeData}
      />
    )}
  </>
  
  );
}
