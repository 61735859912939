import React, { useEffect, useState } from "react";
import HomeBanners from "./homebanners";
import axios from "axios";
import { configuration } from "../helpers/config";
import { useSelector } from "react-redux";

const LobPage = (props) => {
  const LOB_VALUE = useSelector((state) => state?.app?.LobValue);
  const initialState = {
    titleColor: "",
    titleFontSize: "",
    descColor: "",
    descFontSize: "",
    ctaColor: "",
    ctaFontSize: "",
  };
  const [banner1ThemeData, setBanner1ThemeData] = useState(initialState);
  const [banner2ThemeData, setBanner2ThemeData] = useState(initialState);
  const [banner3ThemeData, setBanner3ThemeData] = useState(initialState);

  let activeBanners = props?.[0] ? props?.[0]?.banners?.filter((item) => item?.is_active) 
                      :props?.banners?.filter((item) => item?.is_active);


  let banners = activeBanners?.map((item) => {
    return {
      ...item,
      image:
        activeBanners.length === 1
          ? item.image1Col
          : activeBanners.length === 2
            ? item.image2Col
            : item.image3Col,
      image_mob: item.image_mob1Col,
    };
  });

  const getThemeData = async () => {
    try {
    // fetching theme data from settings api
    const { data } = await axios.get(
      `${configuration.APP_URL_CONTENTS}/settings?lob=1`,
    );
    // assigning theme data
    if (data?.length > 0) {
      const themeData = data[0]?.theme_settings?.find(
        (x) => x?.applied === true,
      );
      if (themeData) {
      setBanner1ThemeData({
        ...banner1ThemeData,
        titleColor: themeData?.landingpage_banner1_title_color,
        titleFontSize: themeData?.landingpage_banner1_title_font_size,
        descColor: themeData?.landingpage_banner1_desc_color,
        descFontSize: themeData?.landingpage_banner1_desc_font_size,
        ctaColor: themeData?.landingpage_banner1_cta_color,
        ctaFontSize: themeData?.landingpage_banner1_cta_font_size,
      });
      setBanner2ThemeData({
        ...banner2ThemeData,
        titleColor: themeData?.landingpage_banner2_title_color,
        titleFontSize: themeData?.landingpage_banner2_title_font_size,
        descColor: themeData?.landingpage_banner2_desc_color,
        descFontSize: themeData?.landingpage_banner2_desc_font_size,
        ctaColor: themeData?.landingpage_banner2_cta_color,
        ctaFontSize: themeData?.landingpage_banner2_cta_font_size,
      });
      setBanner3ThemeData({
        ...banner3ThemeData,
        titleColor: themeData?.landingpage_banner3_title_color,
        titleFontSize: themeData?.landingpage_banner3_title_font_size,
        descColor: themeData?.landingpage_banner3_desc_color,
        descFontSize: themeData?.landingpage_banner3_desc_font_size,
        ctaColor: themeData?.landingpage_banner3_cta_color,
        ctaFontSize: themeData?.landingpage_banner3_cta_font_size,
      });
    } else {
      console.warn("No applied theme settings found.");
      }
    }
    else {
      console.warn("No theme data returned from API.");
      }
    }catch (error) {
      if (error.response) {
        console.log("API Error:", error.response.data);
        console.log("Status Code:", error.response.status);
      } else if (error.request) {
        console.log("No response received from API:", error.request);
      } else {
        console.log("Error in API request:", error.message);
        }
      }
  };

  useEffect(() => {
    if (!LOB_VALUE) return;
    getThemeData();
  }, []);

  return (
    <div className="md:overflow-hidden md:h-[100vh]">
      <HomeBanners
        banners={banners}
        banner1ThemeData={banner1ThemeData}
        banner2ThemeData={banner2ThemeData}
        banner3ThemeData={banner3ThemeData}
      />
    </div>
  );
};

export default LobPage;
